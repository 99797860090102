import React from 'react';
import uploadIcon from '~img/icons/upload.svg';
import settingIcon from '~img/icons/setting.svg';

const ClientItem = ({ profile, getClientProfile, openModal, fileChange }) => {
    return (
        <div key={profile.id} className="clients__item">
            <div className="clients__item-info" onClick={() => getClientProfile(profile.client_name)}>
                {profile.client_logo && (
                    <img
                        className="clients__item-logo"
                        alt="clinic logo"
                        src={`${window.location.origin.replace('3000', '8000')}${profile.client_logo}`}
                    />
                )}
                {profile.client_name && <div className="clients__item-name">{profile.client_name}</div>}
            </div>
            <div className="clients__item-icon clients__item-setting">
                <button type="button" onClick={() => openModal(profile.user)} className="clients__item-upload-icon">
                    <img src={settingIcon} alt="Настройка" />
                </button>
            </div>
            <div className="clients__item-icon clients__item-upload">
                <label className="clients__item-upload-icon">
                    <img src={uploadIcon} alt="Загрузка" />
                    <input
                        type="file"
                        name="file"
                        id={profile.id}
                        className="upload__input"
                        onChange={fileChange}
                    />
                </label>
                <div className="clients__item-upload-text bold">Загрузить логотип клиники</div>
            </div>
        </div>
    );
};

export default ClientItem;
